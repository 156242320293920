import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const childrenSlice = createSlice({
  name: "children",
  initialState: {
    children: [],
    fullBirthdate: null,
    trialCoursesCost: null,
  },
  reducers: {
    childrenReceived: (children, action) => {
      children.children = action.payload.students;
      children.fullBirthdate = action.payload.full_birthdate;
      children.trialCoursesCost = action.payload.trial_courses_cost;
    },
  },
});

export const { childrenReceived } = childrenSlice.actions;

export default childrenSlice.reducer;

export const loadChildren = createAsyncThunk(
  "children/fetchChildren",
  async (payload, { dispatch }) => {
    var fullBirthday = "";
    const response = await axios
      .get(`${process.env.REACT_APP_BACKEND}/ajax_l4/students_courses_vue`)
      .then((resp) => {
        if (resp.status === 200) {
          return resp.data;
        }
      });
    response.students = response.students.map((v) => {
      if (fullBirthday) {
        v.child.childYearBorn = v.child.born.substring(6, 10);
      } else {
        v.child.childYearBorn = v.child.born;
      }
      v.child.coursesKey = `child_courses_${v.child.id}`;
      return v;
    });

    // console.log("childrenSlice:::", response);
    dispatch(childrenReceived(response));
  }
);
