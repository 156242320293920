import { React } from "react";

import FormInput from "./FormInput.js";
import "./Forms.css";

function EditableCell(props) {
    const {editId, id, label, name, value, handleChange, ...inputProps} = props;
    return (
        <>
            {editId == id
             ? <FormInput
                   label={label}
                   name={name}
                   value={value}
                   { ...inputProps }
                   handleChange={handleChange}
               />
             : value}
            
        </>
    )
}

export default EditableCell;
