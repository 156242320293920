import axios from "axios";
// import { apiDomain } from "./Const";
import { useNavigate } from "react-router-dom";



function HeaderComponent() {
    const navigate = useNavigate();
    const logout = () => {
    const logoutQuery = async () => {
        await axios.get(
            `${process.env.REACT_APP_BACKEND}/api_logout`,
            {
                withCredentials: true,
            });
        navigate("/", { replace: true });
    };

        logoutQuery();
    };
  return (
    <div className="App-header">
          <h1>
          {process.env.REACT_APP_NAME}
          <button onClick={logout}
      style={{float: "right", fontSize: "14px"}}> Logout</button>
          </h1>
    </div>
  )
}

export default HeaderComponent;
