import { FaXmark } from "react-icons/fa6";
import axios from "axios";
import { useEffect, useState } from "react";

export default function TrialCorse(props) {
    const course = props.course;
    const [isTrial, setIsTrial] = useState(course.trial);
    const [msg, setMsg] = useState("");
    const [title, setTitle] = useState("");
    useEffect(() => {
        if (isTrial) {
            setMsg("Prøvetime");
            setTitle("Gjør om til kursplass");
        } else {
            setMsg("Kursplass");
            setTitle("Gjør om til prøvetime");
        }
    }, [isTrial]);

    const toggleTrial = async () => {
        let data = new FormData();
        data.append("booking_id", course.id);
        await axios
            .post(`${process.env.REACT_APP_BACKEND}/ajax/trial_it`, data)
            .then((response) => {
                if (response.status === 200) {
                    setIsTrial(!isTrial);
                }
            });
    };

    return (
        <span title={title}>
            {msg}
            <FaXmark onClick={toggleTrial} style={{ color: "red" }} />
        </span>
    );
}
