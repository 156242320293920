export default function OrderTotals(props) {
  const totals = props.totals;
  let r = [];
  if (totals != null) {
    if ("sum" in totals) r.push({ label: "Sum", val: totals.sum });
    if ("discount" in totals && totals.discount > 0)
      r.push({ label: "Discount", val: totals.discount });
    if (totals.invoice_fee > 0)
      r.push({ label: "Invoice fee", val: totals.invoice_fee });
    if (totals.membership_sum > 0)
      r.push({ label: "Membership", val: totals.membership_sum });
    if (totals.payed > 0) r.push({ label: "Payed", val: totals.payed });
    if ("due_sum" in totals) r.push({ label: "Due sum", val: totals.due_sum });
  }

  return (
    <>
      {r.map((l) => (
        <tr key={l.label}>
          <td colSpan={5}>{l.label}</td>
          <td>{l.val}</td>
        </tr>
      ))}
    </>
  );
}
