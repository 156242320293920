import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaPen,
  FaCheck,
  FaCircleXmark,
  FaRegAddressBook,
  FaCircle,
} from "react-icons/fa6";

import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import TrialCorse from "./TrialCourse.jsx";

import "./Forms.css";

import EditableCell from "./EditableCell.js";
import { loadOrders } from "../features/orderSlice.js";
import { useDispatch, useSelector } from "react-redux";
import { loadChildren } from "../features/childrenSlice.js";

function ChildrenList(props) {
  // const [students, setStudents] = props.students;
  const dispatch = useDispatch();
  // const students = [];
  const students = useSelector((state) => state.children);
  // const [fullBirthday, setFullBirthday] = useState();
  let navigate = useNavigate();

  const [editId, setEditId] = useState(null);
  // const dispatch = useDispatch;
  // const orders = useSelector((state) => state.orders);

  function edit(id) {
    setEditId(id);
  }

  const handleChange = (e) => {
    console.log(editId, e.target.name, e.target.value);
    //setPar({ ...students, [e.target.name]: e.target.value })
    //setStudents({  });
  };

  // const apiURL = `${process.env.REACT_APP_BACKEND}/ajax_l4/students_courses_vue`;
  // async function fetchData() {
  //     await axios
  //         .post(`${process.env.REACT_APP_BACKEND}/ajax_l4/students_courses_vue`, {
  //             order_id: "",
  //         })
  //         .then((response) => {
  //             if (response.status === 200) {
  //                 // console.log(response.data.students);
  //                 setFullBirthday(response.data.full_birthdate);
  //                 let stud = response.data.students;
  //                 stud = stud.map((v) => {
  //                     if (fullBirthday) {
  //                         v.child.childYearBorn = v.child.born.substring(6, 10);
  //                     } else {
  //                         v.child.childYearBorn = v.child.born;
  //                     }
  //                     v.child.coursesKey = `child_courses_${v.child.id}`;
  //                     return v;
  //                 });
  //                 setStudents(stud);
  //             }
  //         });
  // }

  function hideStudent(studentId) {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure to do thisdelete student?",
      buttons: [
        {
          label: "Yes",
          onClick: () => hideStudentRequest(studentId),
        },
        {
          label: "No",
          onClick: () => {
            console.log("no");
          },
        },
      ],
    });
  }
  async function hideStudentRequest(studentId) {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND}/ajax_l4/hide_child`,
        new URLSearchParams({ child_id: studentId }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch(loadChildren());
        }
      });
  }

  async function booking(child) {
    navigate("/courses", { replace: true, state: { child: child } });
  }
  const delBookedCourse = async (c) => {
    // console.log(`delete booked course ${c}`);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND}/ajax_l4/del_book_it`,
        new URLSearchParams({ booking_id: c })
      )
      .then((response) => {
        if (response.status === 200) {
          console.log(("delete::", c));
          dispatch(loadChildren());
          dispatch(loadOrders());
        }
      });
  };
  useEffect(() => {
    dispatch(loadChildren());
  }, [dispatch]);

  async function saveStudent() {
    edit(null);
  }

  return (
    <>
      <table width={"100%"}>
        <tbody>
          {students.children.map((row) => {
            return (
              <>
                <tr key={row.child.id}>
                  <td>
                    <EditableCell
                      editId={editId}
                      id={row.child.id}
                      label="Navn"
                      name="name"
                      value={row.child.name}
                      handleChange={handleChange}
                    />
                    {editId === row.child.id ? "" : " "}
                    <EditableCell
                      editId={editId}
                      id={row.child.id}
                      label="Etternavn"
                      name="name2"
                      value={row.child.name2}
                      handleChange={handleChange}
                    />
                  </td>
                  <td>
                    <EditableCell
                      editId={editId}
                      id={row.child.id}
                      label="Mobil"
                      name="mobile_phone"
                      value={row.child.mobile_phone}
                      handleChange={handleChange}
                    />
                    {editId === row.child.id ? "" : " "}
                    <EditableCell
                      editId={editId}
                      id={row.child.id}
                      label="E-post"
                      name="email"
                      value={row.child.email}
                      handleChange={handleChange}
                    />
                  </td>
                  <td>
                    <EditableCell
                      editId={editId}
                      id={row.child.id}
                      label="Born"
                      name="born"
                      value={row.child.born}
                      style={{ width: "100px" }}
                      handleChange={handleChange}
                    />
                  </td>
                  <td style={{ width: "60px" }}>
                    {editId === row.child.id ? (
                      <FaCheck title="Save" onClick={() => saveStudent()} />
                    ) : (
                      <FaPen title="Edit" onClick={() => edit(row.child.id)} />
                    )}
                    &nbsp;
                    {editId === row.child.id ? (
                      <FaCircleXmark
                        title="Cancel"
                        onClick={() => edit(null)}
                      />
                    ) : (
                      <FaCircleXmark
                        title="Delete student profile"
                        onClick={() => hideStudent(row.child.id)}
                      />
                    )}
                    &nbsp;
                    {editId === row.child.id ? (
                      <div style={{ width: "14px" }} />
                    ) : (
                      <FaRegAddressBook
                        title="Booking courses"
                        onClick={() => booking(row.child)}
                      />
                    )}
                  </td>
                </tr>
                <tr key={row.child.coursesKey}>
                  <td colSpan={4}>
                    <table width={"100%"}>
                      <tbody>
                        {row.courses.map((course) => {
                          let statusColor;
                          if (course.hide_from_participants) {
                            statusColor = { color: "gray" };
                          } else if (course.status === 1) {
                            statusColor = { color: "green" };
                          } else if (course.status === 2) {
                            statusColor = { color: "yellow" };
                          } else {
                            statusColor = { color: "red" };
                          }
                          return (
                            <tr key={course.id}>
                              <td>
                                <FaCircleXmark
                                  title="Delete course fom the Order"
                                  onClick={() => delBookedCourse(course.id)}
                                />
                              </td>
                              <td>{course.name_id}</td>
                              <td>{course.name}</td>
                              <td>{course.date_start}</td>
                              <td>
                                <FaCircle style={statusColor} />
                              </td>

                              <td>
                                <TrialCorse course={course} />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default ChildrenList;
