import { React } from "react";
import ParentComponent from "./ParentComponent";
// import { apiDomain} from "./Const";

// axios.defaults.withCredentials = true;

function BookingComponent() {

    return(
        <div>
            <ParentComponent />
        </div>
    );
}

export default BookingComponent;
