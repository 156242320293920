import "./Forms.css";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { loadOrders } from "../features/orderSlice";
import { useEffect } from "react";
import OrderTotals from "./OrderTotals";
import Button from "react-bootstrap/Button";
import axios from "axios";

export default function Orders(props) {
  const par = props.par;
  const students = props.students;
  const comp = props.comp;
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders);
  var checkboxCondition = true;
  const goToPayment = () => {
    let url = `${process.env.REACT_APP_BACKEND}/ajax_l4/confirm_order`;
    let data = new FormData();
    data.append("order_id", orders.order.id);
    data.append("condition", "");
    data.append("checkbox_condition", checkboxCondition);

    console.log(orders);
    axios.post(url, data);
    //window.location.href = "https://www.google.com";
  };
  // console.log("Order lines >>", orders);
  const renderedOrderLines = orders.lines.map((i) => (
    <tr key={i.id}>
      <td>
        {i.child.name} {i.child.name2}
      </td>
      <td>{i.name_id}</td>
      <td>{i.name}</td>
      <td>{i.cost}</td>
      <td>{i.qty}</td>
      <td>{i.cost}</td>
    </tr>
  ));
  const totalAmounts = orders.order.comp_amounts;
  useEffect(() => {
    dispatch(loadOrders());
  }, [dispatch, students]);
  return (
    <>
      <div className="row">
        <div className="col">
          {par.name} {par.name2}
        </div>
        <div className="col">{comp.name}</div>
      </div>
      <div className="row">
        <div className="col">{par.address1}</div>
        <div className="col">{comp.visit_address1}</div>
      </div>
      <div className="row">
        <div className="col">
          {par.pono} {par.city}
        </div>
        <div className="col">
          {comp.visit_zip} {comp.visit_city}
        </div>
      </div>
      <div className="row">
        <div className="col">
          Order No. {orders.order.order_no}{" "}
          {dayjs(orders.order.order_date).format("DD.MM.YYYY")}
        </div>
      </div>
      <table width="100%">
        <tbody>
          {renderedOrderLines}
          <OrderTotals totals={totalAmounts} />
        </tbody>
      </table>

      <Button onClick={goToPayment}>Payment</Button>
    </>
  );
}
