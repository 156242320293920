import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const ordersSlice = createSlice({
  name: "orders",
  initialState: {
    lines: [],
    order: {},
    loading: false,
  },
  reducers: {
    ordersReceived: (orders, action) => {
      orders.order = action.payload.order;
      orders.lines = action.payload.booked_courses;
      orders.loading = false;
    },
  },
});

export const { ordersReceived } = ordersSlice.actions;

export default ordersSlice.reducer;

export const loadOrders = createAsyncThunk(
  "orders/fetchPosts",
  async (payload, { dispatch }) => {
    const response = await axios
      .get(`${process.env.REACT_APP_BACKEND}/booked_courses_json`)
      .then((resp) => {
        if (resp.status === 200) {
          // console.log("order slice >>", resp.data);

          return resp.data;
        }
      });
    dispatch(ordersReceived(response));
  }
);
