import { React } from "react";


function FormInput(props) {
    // const [focused, setFocused] = useState(false); 
    const { label, handleChange, ...inputProps } = props;
    // const handleFocus = (e) => {
    //     setFocused(true);
    // };
    
    return (
        <div className="col-sm">
            <label>
	        { label }
	    </label>
            <input
                type="text"
                { ...inputProps }
                onChange={handleChange}
            />
        </div>

    )
}

export default FormInput;
