import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import { apiDomain } from "./Const";

function LoginPage() {
  const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    let navigate = useNavigate();
    let [msg, setMsg] = useState("");

    async function handleLogin() {
        if (username && password) {
            let loginURL = `${process.env.REACT_APP_BACKEND}/simple_login`;
            let data = new FormData();
            data.append("l_name", username);
            data.append("l_pass", password);
            data.append("act", "password");

            const response = await axios.post(loginURL, data);
            if (response.data.status === "OK") {
                navigate('/booking', { replace: true });
            } else {
                setMsg(response.data.msg);
                console.log("login", msg);
            }
        } else {
            console.log("Invalid username or password");
        }
    };

  return (
    <div>
      <h1>Login Page</h1>
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
        <div style={{color: "rgb(232, 59, 70)"}}>
            {msg}
        </div>
      <button onClick={handleLogin}>Login</button>
    </div>
  );
}

export default LoginPage;
