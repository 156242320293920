import Button from "react-bootstrap/Button";
import axios from "axios";
import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

export default function BookingButton(props) {
  const course = props.course;
  const child = props.child;
  const childYearBorn = props.childYearBorn;
  const orderId = props.orderId;
  let btn;
  // console.log("course ", course);

  const bookIt = async (course) => {
    const course_id = course.id;
    course.enable = false;
    course.orders.push("current booking");
    let bookingRequestData = new FormData();
    bookingRequestData.append("course_id", course_id);
    bookingRequestData.append("child_id", child.id);
    bookingRequestData.append("order_id", orderId);
    bookingRequestData.append("force_book", true);
    await axios
      .post(`${process.env.REACT_APP_BACKEND}/ajax/book_it`, bookingRequestData)
      .then((response) => {
        if (response.status === 200) {
          const rez = response.data;
          const html = rez["html"];
          if (html === "OK") {
            // const book_id = rez["book_id"];
            toast("Takk, husk at påmeldingen må bekreftes før du avslutter ");
          } else if (html === "3") {
            toast(
              "Elevens alder passer ikke til dette kurset, vennligst velg et annet kurs."
            );
          } else if (html === "2") {
            toast(
              "Dette kurset har venteliste. Ønsker du fortsatt å melde deg på dette kurset ?"
            );
          } else {
            alert(html);
          }
        }
      });
  };

  if (childYearBorn < course.age_from || childYearBorn > course.age_till)
    btn = (
      <div className="btn">Elevens alder passer ikke til dette kurset!</div>
    );
  else if (course.orders[0] === "current booking")
    return <div className="btn">Plassen er reservert!</div>;
  else {
    if (course.course_status === 4) return <div>Kurset er fullbooket</div>;
    else if (course.course_status === 3)
      btn = (
        <Button variant="outline-primary" disabled>
          Meld deg på ventelisten
        </Button>
      );
    else if (course.course_status === 1 || course.course_status === 2)
      btn = (
        <Button variant="outline-primary" onClick={() => bookIt(course)}>
          Meld deg på kurs
        </Button>
      );
    else
      btn = (
        <>
          <Button variant="outline-primary" onClick={() => bookIt(course)}>
            Meld deg på kurs
          </Button>
        </>
      );
  }
  return <>{btn}</>;
}
