import Course from "./Course.jsx";

function DayCourses(props) {
    const coursesList = props["coursesList"];
    const day = props["day"];
    const placeSelected = props.placeSelected;
    const childYearBorn = props.childYearBorn;
    const child = props.child;

    // console.log('props :', props.coursesList);
    return (
        <div className="day">
            <div>Day {day}</div>
            <div style={{ width: "100%" }}></div>
            {coursesList
                .filter(
                    (course) => course.place === placeSelected || placeSelected === "All"
                )
                .map((i) => {
                    return (
                        <Course course={i} child={child} childYearBorn={childYearBorn} />
                    );
                })}
        </div>
    );
}

export default DayCourses;
