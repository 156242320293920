import FormInput from "./FormInput";

import "./Forms.css";

function ExtraParentsForm(props) {
  const { par, handleChange } = props;

  return (
    <>
      <div className="row">
        <FormInput
          label="Navn"
          name="sp1_name"
          value={par.sp1_name}
          handleChange={handleChange}
        />
        <FormInput
          label="Etternavn"
          name="sp1_name2"
          value={par.sp1_name2}
          handleChange={handleChange}
        />
      </div>
      <div className="row">
        <FormInput
          label="E-post"
          name="sp1_email"
          value={par.sp1_email}
          handleChange={handleChange}
        />
        <FormInput
          label="Telefon"
          name="sp1_phone"
          value={par.sp1_phone}
          handleChange={handleChange}
        />
      </div>
      <div className="row">
        <FormInput
          label="Adresse"
          name="sp1_address1"
          value={par.sp1_address1}
          handleChange={handleChange}
        />
        <FormInput
          label="Adresse"
          name="sp1_address2"
          value={par.sp1_address2}
          handleChange={handleChange}
        />
      </div>
      <div className="row">
        <FormInput
          label="Po.num."
          name="sp1_pono"
          value={par.sp1_pono}
          handleChange={handleChange}
        />
        <FormInput
          label="Sted/By"
          name="sp1_city"
          value={par.sp1_city}
          handleChange={handleChange}
        />
      </div>

      <hr />

      <div className="row">
        <FormInput
          label="Navn"
          name="sp2_name"
          value={par.sp2_name}
          handleChange={handleChange}
        />
        <FormInput
          label="Etternavn"
          name="sp2_name2"
          value={par.sp2_name2}
          handleChange={handleChange}
        />
      </div>
      <div className="row">
        <FormInput
          label="E-post"
          name="sp2_email"
          value={par.sp2_email}
          handleChange={handleChange}
        />
        <FormInput
          label="Telefon"
          name="sp2_phone"
          value={par.sp2_phone}
          handleChange={handleChange}
        />
      </div>
      <div className="row">
        <FormInput
          label="Adresse"
          name="sp2_address1"
          value={par.sp2_address1}
          handleChange={handleChange}
        />
        <FormInput
          label="Adresse"
          name="sp2_address2"
          value={par.sp2_address2}
          handleChange={handleChange}
        />
      </div>
      <div className="row">
        <FormInput
          label="Po.num."
          name="sp2_pono"
          value={par.sp2_pono}
          handleChange={handleChange}
        />
        <FormInput
          label="Sted/By"
          name="sp2_city"
          value={par.sp2_city}
          handleChange={handleChange}
        />
      </div>

      <hr />

      <div className="row">
        <FormInput
          label="Navn"
          name="sp3_name"
          value={par.sp3_name}
          handleChange={handleChange}
        />
        <FormInput
          label="Etternavn"
          name="sp3_name2"
          value={par.sp3_name2}
          handleChange={handleChange}
        />
      </div>
      <div className="row">
        <FormInput
          label="E-post"
          name="sp3_email"
          value={par.sp3_email}
          handleChange={handleChange}
        />
        <FormInput
          label="Telefon"
          name="sp3_phone"
          value={par.sp3_phone}
          handleChange={handleChange}
        />
      </div>
      <div className="row">
        <FormInput
          label="Adresse"
          name="sp3_address1"
          value={par.sp3_address1}
          handleChange={handleChange}
        />
        <FormInput
          label="Adresse"
          name="sp3_address2"
          value={par.sp3_address2}
          handleChange={handleChange}
        />
      </div>
      <div className="row">
        <FormInput
          label="Po.num."
          name="sp3_pono"
          value={par.sp3_pono}
          handleChange={handleChange}
        />
        <FormInput
          label="Sted/By"
          name="sp3_city"
          value={par.sp3_city}
          handleChange={handleChange}
        />
      </div>
      <button type="submit">Submit parent&extra parents</button>
    </>
  );
}

export default ExtraParentsForm;
