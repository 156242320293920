import { configureStore } from "@reduxjs/toolkit";
import childrenSlice from "../features/childrenSlice";
import ordersSlice from "../features/orderSlice";

export default configureStore({
  reducer: {
    orders: ordersSlice,
    children: childrenSlice,
  },
});
