import "./Forms.css";

function ParentForm(props) {
  const { par, handleChange } = props;

  return (
    <>
      <div className="row">
        <div className="col-sm">
          <label className="lab">Fornavn foreldre/foresatte:</label>
          <input
            type="text"
            name="name"
            value={par.name || ""}
            onChange={handleChange}
            required="required"
          />
        </div>
        <div className="col-sm">
          <label className="lab">Etternavn foreldre/foresatte:</label>
          <input
            type="text"
            name="name2"
            value={par.name2 || ""}
            onChange={handleChange}
            id="id_name2"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm">
          <label className="lab">Adresse:</label>
          <input
            type="text"
            name="address1"
            value={par.address1 || ""}
            onChange={handleChange}
            id="id_address1"
          />
        </div>
        <div className="col-sm">
          <div className="row">
            <div className="col-4">
              <label className="lab">Postnummer:</label>
              <input
                type="text"
                name="pono"
                value={par.pono || ""}
                onChange={handleChange}
                id="id_pono"
                size="5"
              />
            </div>
            <div className="col-8">
              <label className="lab">By/Sted:</label>
              <input
                type="text"
                name="city"
                value={par.city || ""}
                onChange={handleChange}
                id="id_city"
                size="10"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm">
          <label className="lab">skriv inn ditt mobil nr:</label>
          <input
            name="mobile_phone"
            type="text"
            value={par.mobile_phone || ""}
            onChange={handleChange}
            title="You can`t edit this field"
          />
        </div>
        <div className="col-sm">
          <label className="lab">skriv inn din epost adresse:</label>
          <input
            id="email"
            name="email"
            type="text"
            value={par.email || ""}
            onChange={handleChange}
            readOnly="readonly"
            title="You can`t edit this field"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm">
          <label className="lab">Oppgi ditt person nr. 11 siffer</label>
          <input
            type="text"
            name="personal_id"
            value={par.personal_id || ""}
            onChange={handleChange}
            id="id_personal_id"
          />
        </div>
      </div>
      <button type="submit">Submit parent&extra parents</button>
    </>
  );
}

export default ParentForm;
