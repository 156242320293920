import { useState } from "react";
import { useSpring, animated } from "react-spring";
import { FaV } from "react-icons/fa6";

export default function AccordionAnimation(props) {
  const [open, setOpen] = useState(props.open);
  const toggle = () => {
    setOpen(!open);
  };
  const iconAnimation = useSpring({
    from: {
      transform: "rotate(0deg)",
      // color: "#ffff"
    },
    to: {
      transform: open ? "rotate(180deg)" : "rotate(0deg)",
      // color: open ? "#10d6f5" : "#fff"
    },
    config: { duration: "120" },
  });
  const openAnimation = useSpring({
    from: {
      opacity: "0",
      maxHeight: "40px",
      overflow: "hidden",
    },
    to: {
      opacity: "1",
      maxHeight: open ? "100%" : "40px",
    },
    config: {
      duration: "300",
    },
  });

  return (
    <>
      <animated.div style={openAnimation}>
        <h2 onClick={toggle} className="title">
          {props.title}
          <animated.div style={iconAnimation}>
            <FaV />
          </animated.div>
        </h2>
        {props.children}
      </animated.div>
    </>
  );
}
