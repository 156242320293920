import { useSelector } from "react-redux";
import BookingButton from "./BookingButton.jsx";

export default function Course(props) {
    const course = props.course;
    const child = props.child;
    const childYearBorn = props.childYearBorn;
    const orders = useSelector((state) => state.orders);
    const orderId = orders.order.id;

    // console.log("order no: ", orderNo);
    return (
        <div className="course-box row">
            <div className="col">
                <div className="row">
                    <div className="col">ID {course.course_id}</div>
                </div>
                <div className="row">
                    <div className="col">{course.title}</div>
                </div>
                <div className="row">
                    <div className="col">{course.day_of_week}</div>
                    <div className="col">kl. {course.start_time}</div>
                </div>
                <div className="row">
                    <div className="col">{course.place}</div>
                    <div className="col">{course.teacher}</div>
                </div>
                <div className="row">
                    <div
                        className="col"
                        dangerouslySetInnerHTML={{ __html: course.description }}
                    ></div>
                </div>
                <div className="row">
                    <div className="col">
                        Alder fra - til: {course.age_from} - {course.age_till}
                    </div>
                </div>

                <div className="row">
                    <div
                        className={`col booking_status booking_status_${course.course_status}`}
                    >
                        <BookingButton
                            course={course}
                            child={child}
                            childYearBorn={childYearBorn}
                            orderId={orderId}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
