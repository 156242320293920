import { React, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { FaArrowLeftLong, FaBackwardStep } from "react-icons/fa6";

import DayCourses from "./components/DayCourses.jsx";
import PlaceFilter from "./components/PlaceFilter.jsx";

import "./components/Courses.css";

function CoursesComponent() {
    let dayKey = 0;
    let navigate = useNavigate();
    const location = useLocation();
    const child = location.state.child;
    const childId = child.id;
    const childYearBorn = child.childYearBorn;
    const [courses, setCourses] = useState([]);
    const [places, setPlaces] = useState([]);
    const [placeSelected, setPlaceSelected] = useState("All");

    // console.log("year born", childYearBorn);

    function goBack() {
        navigate("/booking", { replace: true });
    }

    const fetchData = async () => {
        await axios
            .get(`${process.env.REACT_APP_BACKEND}/ajax_l4/courses_vue/${childId}`)
            .then((response) => {
                if (response.status === 200) {
                    const coursesServer = response.data.courses;
                    setCourses(coursesServer);
                    setPlaces(response.data.places);

                    // console.log('111 ', response.data.courses);
                }
            });
    };

    function placeFilter(f) {
        console.log(`filter ${f}`);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <ToastContainer />
            <h2>
                <FaArrowLeftLong title="Go back to the main page" onClick={goBack} />
                Courses list
            </h2>
            <PlaceFilter places={places} setPlaceSelected={setPlaceSelected} />

            {Object.keys(courses).map((day, key) => {
                //console.log("day ", day, courses[day].length);
                let l = courses[day].length == 0;
                dayKey = dayKey + 1;
                return (
                    <>
                        {l ? (
                            ""
                        ) : (
                            <DayCourses
                                coursesList={courses[day]}
                                child={child}
                                day={day}
                                key={dayKey}
                                placeSelected={placeSelected}
                                childYearBorn={childYearBorn}
                            />
                        )}
                    </>
                );
            })}
        </>
    );
}

export default CoursesComponent;
