import { useState } from "react";
import Button from 'react-bootstrap/Button';

export default function PlaceFilter(props) {
    const places = props.places;
    const setPlaceSelected = props.setPlaceSelected;
    return (
        <>
            <Button onClick={() => setPlaceSelected("All")}>
                All
            </Button>{" "}
            {places.map((place) => {
                return (
                    <>
                    <Button onClick={() => setPlaceSelected(place)}>
                        {place}
                    </Button>{" "}
                    </>
                )
            })}
           

        </>
    )
}
