import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./LoginPage";
//import ProtectedPage from './ProtectedPage'; // Example protected page component
import "./App.css";
import BookingComponent from "./BookingComponent";
import CoursesComponent from "./CoursesComponent.js";
import HeaderComponent from "./Header";

function App() {
    return (
        <div className="App">
    <BrowserRouter>
      <HeaderComponent />
      <Routes>
          <Route path="/" element={<LoginPage />} />
         <Route path="/booking" element={<BookingComponent />} />
         <Route path="/courses" element={<CoursesComponent />} />
        {/* <Route path="/" component={LoginPage} /> */}
      </Routes>
          </BrowserRouter>
          </div>
  );
}

export default App;
