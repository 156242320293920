import { React, useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AccordionAnimation from "./components/AccordionAnimation";

// import { useSelector, useDispatch } from "react-redux";
// import { updateOrder } from "./features/orderSlice";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./ParentComponent.css";

import ExtraParentsForm from "./components/ExtraParentsForm.js";
import ParentForm from "./components/ParentForm.js";
import ChildrenList from "./components/ChildrenList.js";
import Orders from "./components/Orders.js";
import ContactsHistory from "./components/ContactsHistory.js";

axios.defaults.withCredentials = true;

function ParentComponent() {
  const [par, setPar] = useState({
    name: "",
    name2: "",
    mobile_phone: "",
    emaiol: "",

    sp1_name: "",
    sp1_name2: "",
    sp1_email: "",
    sp1_phone: "",
    sp1_address1: "",
    sp1_address2: "",
    sp1_pono: "",
    sp1_city: "",

    sp2_name: "",
    sp2_name2: "",
    sp2_email: "",
    sp2_phone: "",
    sp2_address1: "",
    sp2_address2: "",
    sp2_pono: "",
    sp2_city: "",

    sp3_name: "",
    sp3_name2: "",
    sp3_email: "",
    sp3_phone: "",
    sp3_address1: "",
    sp3_address2: "",
    sp3_pono: "",
    sp3_city: "",
  });
  const [students, setStudents] = useState([]);
  const [comp, setComp] = useState({
    name: "",
    visit_address1: "",
    visit_zip: "",
    visit_city: "",
  });
  // const [order, setOrder] = useState({ order_id: 777 });
  const apiURL = `${process.env.REACT_APP_BACKEND}/parent_json`;
  const fetchData = async () => {
    //const response =
    await axios
      .get(
        apiURL //, { withCredentials: true }
      )
      .then((response) => {
        if (response.status === 200) {
          setPar(response.data[0].fields);
          //console.log(par);
        }
      });
  };
  const fetchComp = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND}/comp_json`)
      .then((response) => {
        if (response.status === 200) {
          setComp(response.data[0].fields);
        }
      });
  };
  // const fetchOrder = async () => {
  //     await axios
  //         .get(`${process.env.REACT_APP_BACKEND}/booked_courses_json`)
  //         .then((response) => {
  //             if (response.status === 200) {
  //                 setOrder(response.data[0].fields);
  //             }
  //         });
  // };

  const handleChange = (e) => {
    //console.log(e.target.name, e.target.value);
    setPar({ ...par, [e.target.name]: e.target.value });
  };

  const saveParent = (event) => {
    event.preventDefault();
    //console.log(par);
    axios
      .post(`${process.env.REACT_APP_BACKEND}/parent_json`, par, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        if (response.status === 200) {
          toast("Parent profile has been updated");
        }
      });
  };

  useEffect(() => {
    fetchData();
    fetchComp();
    // fetchOrder();
  }, []);

  return (
    <>
      <ToastContainer />

      <form onSubmit={saveParent}>
        <AccordionAnimation title="Parent information" open={true}>
          <ParentForm par={par} handleChange={handleChange} />
        </AccordionAnimation>

        <AccordionAnimation title="Extra Parens information" open={false}>
          <ExtraParentsForm par={par} handleChange={handleChange} />
        </AccordionAnimation>
      </form>

      <AccordionAnimation title="Children/Courses lisst" open={true}>
        <ChildrenList students={[students, setStudents]} />
      </AccordionAnimation>

      <AccordionAnimation title="Order" open={true}>
        <Orders par={par} comp={comp} students={students} />
      </AccordionAnimation>
    </>
  );
}

export default ParentComponent;
